import { Container, Form, Row, Col, Button, Card } from "react-bootstrap";
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faCopy } from '@fortawesome/free-solid-svg-icons';
import BRIInfaq from "../../assets/bri_logo.png";
import SpayInfaq from "../../assets/spay_infaq.jpg";
import WaLogo from "../../assets/wa_infaq.png";
import EBookPoster from "../../assets/ebook_poster.png";
import Swal from 'sweetalert2';
import BookSection from "./BookSection";

export default function Download() {
    const [ confirmation, setConfirmation ] = useState("");
    const [ infaq, setInfaq ] = useState("");
    const [ customInfaq, setCustomInfaq ] = useState("");
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const copyAbleClick = (props) => {
        navigator.clipboard.writeText(props.number)
        Swal.fire({
            text: `Rekening ${props.name} telah disalin!`,
            icon: "success",
            showConfirmButton: false,
            timer: 1000,
            width: "80%",
          });
    };

    const confirmationLabel = [
        "Sudah", 
        "Belum"
    ];

    const infaqLabel = [
        "Rp. 10.000,00",
        "Rp. 30.000,00",
        "Rp. 50.000,00",
        "Rp. 100.000,00",
        "Lainnya",
    ];
    const infaqPayment = [
        {
            logo: BRIInfaq,
            name: "BRI", 
            number: "0512-01-021009-50-5",
            desc: "a.n. Aulia Tsaabita Qurrotu'ain",
        },
        {
            logo: SpayInfaq,
            name: "ShoopePay", 
            number: "081235121276",
            desc: "a.n. ACI / Fatimah Az Zahra",
        },
    ];
    const waNumConfirmation = "+6281235121276";

    const broadcastContent = infaqPayment.map(p => `(${p.name}) ${p.number} ${p.desc}`)
        .join("\n");
    const broadcastOnClick = (props) => {
        navigator.clipboard.writeText(broadcastContent);
        Swal.fire({
            text: `Rekening telah disalin!`,
            icon: "success",
            showConfirmButton: false,
            timer: 1000,
            width: "80%",
          });
    }

    const emptyForm = () => {
        setConfirmation("");
        setInfaq("");
        setCustomInfaq("");
    };

    const submitForm = (e) => {
        e.preventDefault();
        fetch("https://docs.google.com/forms/d/e/1FAIpQLSdWb-oVgP3r9XhMi4NFIyVnZlIDodXEhOxx92SeZYe-ouGBCQ/formResponse?"
                + new URLSearchParams({
                    "entry.104446084": confirmation,
                    "entry.689048001": infaq,
                    "entry.600620686": customInfaq,
                }),
                {
                    mode: "no-cors",
                }).then((response) => {
                    emptyForm();
                    handleShow();
                })
                .catch((err) => Swal.fire({
                    title: "Terdapat kesalahan...",
                    text: "Silahkan chat admin apabila menemui kesulitan ya😅",
                    icon: "error",
                }));
    };

    return (
        <Container className="download mb-4">
            <Modal show={show} onHide={handleClose} fullscreen={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Silahkan unduh buku 😁 </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="ebook-modal__warning">
                        <div className="ebook-modal__warning-title">
                            WAJIB DIBACA
                        </div>
                        <div className="ebook-modal__warning-item">
                        📚 Dilarang memperbanyak, mencetak, merubah, dan mengkomerialkan ebook tanpa izin tertulis dari penulis. Hak cipta dilindungi undang undang.
                        </div>
                        <div className="ebook-modal__warning-item">
                        🔗 Boleh di share ke siapa saja, tapi kirim dalam bentuk Link activistclass.com ya, jangan file-nya langsung!
                        </div>
                        <div className="ebook-modal__warning-item">
                        📖 Selamat membaca, semoga bermanfaat !
                        </div>
                        <div className="ebook-modal__warning-item">
                        ✍️ Isi buku sepenuhnya tanggung jawab penulis
                        </div>
                    </div>
                    <BookSection />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="ebook__poster">
                <img src={EBookPoster} className="" alt="Poster" />
            </div>
            
            
            <div className="download__form">
                <div className="download__form-title">
                    Silahkan isi terlebih dahulu
                </div>

                <Form className="download__form-item" onSubmit={ submitForm }>
                    <Form.Group className="mb-3" controlId="reqs__confirm">
                        <Form.Label className="registration__form-label" required>
                            Apa kemarin kamu sudah pernah mendaftar acara "Kelas Akselerasi Aktivis Dakwah Kampus" yang diselenggarakan 27-28 April 2024?
                            <sup><FontAwesomeIcon icon={faAsterisk} className="text-danger"/></sup>
                        </Form.Label>
                        <Form.Check
                            className="registration__form-check"
                            id="reqs_confirmation-y"
                            name="confirmation"
                            type="radio"
                            label={ confirmationLabel[0] } 
                            checked={ confirmation === confirmationLabel[0] }
                            onClick={(e) => {
                                if (confirmation !== confirmationLabel[0]) {
                                    setConfirmation(confirmationLabel[0]);
                                } else {
                                    setConfirmation("");
                                }
                            }}/>
                        <Form.Check
                            className="registration__form-check"
                            id="reqs_confirmation-n"
                            name="confirmation"
                            type="radio"
                            label={ confirmationLabel[1] } 
                            checked={ confirmation === confirmationLabel[1] }
                            onClick={(e) => {
                                if (confirmation !== confirmationLabel[1]) {
                                    setConfirmation(confirmationLabel[1]);
                                } else {
                                    setConfirmation("");
                                }
                            }}/>
                    </Form.Group>
                    {
                        confirmation === "Belum" ? (
                            <>
                                <Form.Group className="mb-3" controlId="regs_infaq">
                                    <Form.Label className="registration__form-label">
                                        Available Infaq Operasional Dakwah !</Form.Label>
                                    <Form.Check
                                        className="registration__form-check"
                                        id="regs_infaq_5"
                                        name="infaq"
                                        type="radio"
                                        label={ infaqLabel[0] } 
                                        checked={ infaq === infaqLabel[0] }
                                        onClick={(e) => {
                                            if (infaq !== infaqLabel[0]) {
                                                setInfaq(infaqLabel[0]);
                                            } else {
                                                setInfaq("");
                                            }
                                        }}/>
                                    <Form.Check
                                        className="registration__form-check"
                                        id="regs_infaq_15"
                                        name="infaq"
                                        type="radio"
                                        label={ infaqLabel[1] } 
                                        checked={ infaq === infaqLabel[1] }
                                        onClick={(e) => {
                                            if (infaq !== infaqLabel[1]) {
                                                setInfaq(infaqLabel[1]);
                                            } else {
                                                setInfaq("");
                                            }
                                        }}/>
                                    <Form.Check
                                        className="registration__form-check"
                                        id="regs_infaq_30"
                                        name="infaq"
                                        type="radio"
                                        label={ infaqLabel[2] } 
                                        checked={ infaq === infaqLabel[2] }
                                        onClick={(e) => {
                                            if (infaq !== infaqLabel[2]) {
                                                setInfaq(infaqLabel[2]);
                                            } else {
                                                setInfaq("");
                                            }
                                        }}/>
                                    <Form.Check
                                        className="registration__form-check"
                                        id="regs_infaq_75"
                                        name="infaq"
                                        type="radio"
                                        label={ infaqLabel[3] } 
                                        checked={ infaq === infaqLabel[3] }
                                        onClick={(e) => {
                                            if (infaq !== infaqLabel[3]) {
                                                setInfaq(infaqLabel[3]);
                                            } else {
                                                setInfaq("");
                                            }
                                        }}/>
                                    <Form.Check
                                        className="registration__form-check"
                                        id="regs_infaq_l"
                                        name="infaq"
                                        type="radio"
                                        label={ infaqLabel[4] } 
                                        checked={ infaq === infaqLabel[4] }
                                        onClick={(e) => {
                                            if (infaq !== infaqLabel[4]) {
                                                setInfaq(infaqLabel[4]);
                                            } else {
                                                setInfaq("");
                                            }
                                        }}/>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="regs_custom-infaq">
                                    <Form.Label className="registration__form-label">Nominal infaq lainnya</Form.Label>
                                    <Form.Control type="number" className="registration__form-input"
                                        value={ infaq !== "Lainnya" ? "" : customInfaq } onChange={ (e) => setCustomInfaq(e.target.value) }
                                        disabled={ infaq !== "Lainnya"} />
                                </Form.Group>
                                    
                                <Card>
                                    <Button className="infaq__broadcast-button" onClick={broadcastOnClick}>
                                        <FontAwesomeIcon icon={faCopy} className="mx-2" />
                                        Salin semua rekening
                                    </Button>
                                    <Row className="infaq__section">
                                        <Col xs={12} md={4} lg={4}>   
                                            <Card className="infaq__card" style={{border: "none"}} onClick={() => copyAbleClick(infaqPayment[0])}>
                                                <Card.Img variant="top" src={infaqPayment[0].logo} />
                                                <Card.Body>
                                                <FontAwesomeIcon icon={faCopy} className="mx-2 infaq-payment-section__copy-icon" />
                                                        <strong>{ infaqPayment[0].number }</strong> ({infaqPayment[0].desc})
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col xs={12} md={4} lg={4}>   
                                            <Card className="infaq__card" style={{border: "none"}} onClick={() => copyAbleClick(infaqPayment[1])}>
                                                <Card.Img variant="top" src={infaqPayment[1].logo} />
                                                <Card.Body>
                                                <FontAwesomeIcon icon={faCopy} className="mx-2 infaq-payment-section__copy-icon" />
                                                        <strong>{ infaqPayment[1].number }</strong> ({infaqPayment[1].desc})
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col xs={12} md={4} lg={4}>   
                                            <Card className="infaq__card" style={{border: "none"}} onClick={() => window.open("https://wa.me/+6285755972375", "_blank")}>
                                                <Card.Img variant="top" src={WaLogo} />
                                                <Card.Body>
                                                    Konfirmasi ke <strong>{ waNumConfirmation }</strong>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Card>
                            </>
                        ) : null
                    }

                    <Form.Group className="mt-4" controlId="regs_submit">
                        <Button type="submit" className="registration__form-button">Kirim!</Button>
                    </Form.Group>
                </Form>
            </div>
        </Container>
    );
}